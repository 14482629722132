import ar_AR from './ar.json';
import bg_BG from './bg_BG.json';
import cs_CZ from './cs_CZ.json';
import da_DK from './da_DK.json';
import de_DE from './de_DE.json';
import el_GR from './el_GR.json';
import en_US from './en_US.json';
import es_ES from './es_ES.json';
import et_EE from './et_EE.json';
import fi_FI from './fi_FI.json';
import fr_FR from './fr_FR.json';
import he_IL from './he_IL.json';
import hr_HR from './hr_HR.json';
import hu_HU from './hu_HU.json';
import it_IT from './it_IT.json';
import ja_JP from './ja_JP.json';
import ko_KR from './ko_KR.json';
import lt_LT from './lt_LT.json';
import lv_LV from './lv_LV.json';
import nb_NO from './nb_NO.json';
import nl_BE from './nl_BE.json';
import nl_NL from './nl_NL.json';
import pl_PL from './pl_PL.json';
import pt_BR from './pt_BR.json';
import pt_PT from './pt_PT.json';
import ro_RO from './ro_RO.json';
import ru_RU from './ru_RU.json';
import sk_SK from './sk_SK.json';
import sl_SI from './sl_SI.json';
import sv_SE from './sv_SE.json';
import th_TH from './th_TH.json';
import tr_TR from './tr_TR.json';
import zh_CN from './zh_CN.json';
import zh_TW from './zh_TW.json';
import zh_HK from './zh_HK.json';

export default {
  ar_AR,
  bg_BG,
  cs_CZ,
  da_DK,
  de_DE,
  el_GR,
  en_US,
  es_ES,
  et_EE,
  fi_FI,
  fr_FR,
  he_IL,
  hr_HR,
  hu_HU,
  it_IT,
  ja_JP,
  ko_KR,
  lt_LT,
  lv_LV,
  nb_NO,
  nl_BE,
  nl_NL,
  pl_PL,
  pt_BR,
  pt_PT,
  ro_RO,
  ru_RU,
  sk_SK,
  sl_SI,
  sv_SE,
  th_TH,
  tr_TR,
  zh_CN,
  zh_TW,
  zh_HK
};
