export enum DeviceType {
  PRINTER_DEVICE = 'PrinterDevice',
  PC_DEVICE = 'PCDevice'
}

export enum DeviceTypeParam {
  PRINTER = 'PRINTER',
  PC = 'PC',
  KEYBOARD = 'KEYBOARD',
  MOUSE = 'MOUSE',
  PEN = 'PEN'
}

export enum FeatureFlagsEnum {
  qualtricsSurvey = 'qualtrics-survey',
  originalFeedbackExperience = 'original-feedback-experience'
}
